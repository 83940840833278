import React, { useEffect, useState } from "react";
import { Button, Toolbar } from "primereact";

export const DetailPanelContain = ({ closePanel }) => {
  const leftToolbarTemplate = () => {
    return <div className="panel-title">Detalle auditoría</div>;
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-times"
            label="Cerrar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = <div className="body"></div>;

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>
      {formTemplate}
    </div>
  );
};
