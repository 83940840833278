import React from "react";
import { Button, Column, DataTable, Toolbar } from "primereact";
import { FilterPanel } from "./FilterPanel";

export const AuditTable = ({
  openNew,
  openDetail,
  customers,
  footerPage,
  loading,
}) => {
  const leftToolbarTemplate = () => {
    return (
      <div className="my-2">
        <div className="fontToolClient">Auditorías</div>
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        label=""
        icon="pi pi-plus"
        className="p-button-success mr-2"
        onClick={openNew}
      />
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => null}
        />

        <Button
          icon={rowData.active ? "pi pi-unlock" : "pi pi-lock"}
          className="p-button-rounded p-button-success mr-2"
          onClick={() => null}
        />

        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => openDetail()}
        />

        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => null}
        />
      </div>
    );
  };

  return (
    <>
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      <FilterPanel lazyParams={[]} setLazyParams={null} initParams={[]} />

      <DataTable
        value={customers}
        responsiveLayout="stack"
        dataKey="id"
        stripedRows
        onSort={null}
        sortField={null}
        sortOrder={null}
        loading={loading}
        emptyMessage="Sin resultados"
        footer={footerPage}
      >
        <Column
          field="name"
          showFilterMenu={false}
          sortable
          header="Nombre"
          alignHeader="left"
        />
        <Column
          field="agent"
          showFilterMenu={false}
          sortable
          header="Agente"
          alignHeader="center"
          bodyClassName={"centerRowData"}
        />
        <Column
          field="form"
          showFilterMenu={false}
          sortable
          header="Formulario"
          alignHeader="center"
          bodyClassName={"centerRowData"}
        />
        <Column
          bodyStyle={{ textAlign: "end", justifyContent: "end" }}
          body={actionBodyTemplate}
          exportable={false}
        />
      </DataTable>
    </>
  );
};
