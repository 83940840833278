import React, { useEffect, useState } from "react";
import { Button, InputSwitch, InputText, Toolbar, Dropdown } from "primereact";

export const AuditPanelContain = ({ onInputChange, closePanel }) => {
  const data = [
    {
      id: "c3b91951-c2a3-014e-49c2-8cc2a6c39bc3",
      name: "Formulario ejemplo 1",
      createDate: "09/09/2024",
      updateDate: "10/09/2024",
      grupos: [],
      user: "Admin",
    },
    {
      id: "40c3a145-c3a0-003c-4152-c299c298c298",
      name: "Nombre del segundo form",
      createDate: "09/09/2024",
      updateDate: "10/09/2024",
      grupos: [],
      user: "Admin",
    },
    {
      id: "c2901f52-6fc3-b5c2-9f4b-c387c288c29f",
      name: "Otro formulario",
      createDate: "09/09/2024",
      updateDate: "10/09/2024",
      grupos: [],
      user: "Test",
    },
    {
      id: "c2b0093d-c28b-c388-c390-436dc2a252c3",
      name: "Ejemplo 4",
      createDate: "09/09/2024",
      updateDate: "10/09/2024",
      grupos: [],
      user: "Test",
    },
  ];

  const [dataForm, setDataForm] = useState(null);

  const leftToolbarTemplate = () => {
    return <div className="panel-title">Detalle formulario</div>;
  };

  const handleSave = () => {};

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <div className="body">
      <div className="column">
        <div className="row name-active">
          <div className="field name">
            <label htmlFor="name">Nombre</label>
            <InputText
              id="name"
              value={""}
              onChange={(e) => onInputChange(e, "name")}
              required
              autoFocus
              autoComplete="off"
              placeholder="Ingresa el nombre"
            />
          </div>

          <div className="field switch">
            <InputSwitch
              checked={false}
              onChange={(e) => onInputChange(e, "active")}
            />
            <label htmlFor="active">Estado</label>
          </div>
        </div>

        <div className="field">
          <label htmlFor="agent">Agente</label>
          <InputText
            id="agent"
            value={""}
            onChange={(e) => onInputChange(e, "agent")}
            required
            autoFocus
            autoComplete="off"
            placeholder="Ingresa el agente"
          />
        </div>

        <div className="field">
          <label htmlFor="dataForm">Formulario</label>
          <Dropdown
            value={dataForm}
            onChange={(e) => setDataForm(e.value)}
            options={data}
            optionLabel="name"
            placeholder="Selecciona un formulario"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>
      {formTemplate}
    </div>
  );
};
